import React, { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"

const ScoresEle = (props) => {
  const { t } = props;
  return <div>
    <h5 className="mt-3">
      <strong>{t("summaryPage.tilesInfoModal.scores.subtitle")}</strong>
    </h5>
    <p className="mt-3">
      {t("summaryPage.tilesInfoModal.scores.description.line1")}
    </p>
    <p className="mt-3">
      {t("summaryPage.tilesInfoModal.scores.description.line2")}
    </p>
  </div>
}

const Scores = withTranslation()(ScoresEle);

const LearnMoreModal = (tileName) => {
  const { t } = tileName;
  const [title, setTitle] = useState("")
  useEffect(() => {
    switch (tileName.tileName.tile) {
      case "pap": setTitle(t("summaryPage.tilesInfoModal.pap.title")); break;
      case "assessment": setTitle(t("summaryPage.tilesInfoModal.assessment.title")); break;
      case "scores": setTitle(t("summaryPage.tilesInfoModal.scores.title")); break;
      case "disorder": setTitle(t("summaryPage.tilesInfoModal.disorder.title")); break;
      case "notifs": setTitle(t("summaryPage.tilesInfoModal.notifs.title")); break;
      case "jumpstart": setTitle(t("summaryPage.tilesInfoModal.jumpstart.title")); break;
      default: setTitle(t("summaryPage.tilesInfoModal.error.title")); break;
    }
  }, [tileName])
  const Description = (tile) => {

    const tileName = tile.tile

    if (tileName === "pap") {
      return (<div>
        <p className="mt-3">
          {t("summaryPage.tilesInfoModal.pap.description.line1.text1")} &nbsp;
          <strong>{t("summaryPage.tilesInfoModal.pap.description.line1.strong")}</strong>&nbsp;
          {t("summaryPage.tilesInfoModal.pap.description.line1.text2")}
        </p>
        <p className="mt-3">
          {t("summaryPage.tilesInfoModal.pap.description.line2")}
        </p>
        <p className="mt-3">
          {t("summaryPage.tilesInfoModal.pap.description.line3")}
        </p>
        <p className="mt-3"><strong>{t("summaryPage.tilesInfoModal.pap.description.point1A.title")}</strong></p>
        <p >
          {t("summaryPage.tilesInfoModal.pap.description.point1A.a1")} &nbsp;
          {t("summaryPage.tilesInfoModal.pap.description.point1A.text")}
        </p>
        <p className="mt-3"><strong>{t("summaryPage.tilesInfoModal.pap.description.point2A.title")}</strong></p>
        <p >
          {t("summaryPage.tilesInfoModal.pap.description.point2A.a2")} &nbsp;
          {t("summaryPage.tilesInfoModal.pap.description.point2A.text")}
        </p>
        <p className="mt-3"><strong>{t("summaryPage.tilesInfoModal.pap.description.point3A.title")}</strong></p>
        <p >
          {t("summaryPage.tilesInfoModal.pap.description.point3A.a3")} &nbsp;
          {t("summaryPage.tilesInfoModal.pap.description.point3A.text")}
        </p>
      </div>)
    } else if (tileName === "assessment") {
      return (
        <div>
          <p className="mt-3">
            {t("summaryPage.tilesInfoModal.assessment.description.line1")}
          </p>
          <p className="mt-3">
            {t("summaryPage.tilesInfoModal.assessment.description.line2")}
          </p>
          <p className="mt-3">
            {t("summaryPage.tilesInfoModal.assessment.description.line3")}
          </p>
          <p className="mt-3">
            {t("summaryPage.tilesInfoModal.assessment.description.line4")}
          </p>
        </div>
      )
    } else if (tileName === "scores") {
      return (<Scores />)
    } else if (tileName === "disorder") {
      return (
        <div>
          <p className="mt-3">
            {t("summaryPage.tilesInfoModal.disorder.description.line1")}
          </p>
          <p className="mt-3" dangerouslySetInnerHTML={{ __html: t("summaryPage.tilesInfoModal.disorder.description.line2") }}></p>
          {/* <ul className="ml-5">
              <li>
                <strong>{t("summaryPage.tilesInfoModal.disorder.description.sections.first")} </strong>
              </li>
              <li>
                <strong>{t("summaryPage.tilesInfoModal.disorder.description.sections.second")}</strong>
              </li>
              <li>
                <strong>{t("summaryPage.tilesInfoModal.disorder.description.sections.third")}</strong>
              </li>
              <li>
                <strong>
                  {t("summaryPage.tilesInfoModal.disorder.description.sections.fourth.strong")}
                </strong> &nbsp;
                {t("summaryPage.tilesInfoModal.disorder.description.sections.fourth.normal")}
              </li>
              <li>
                <strong>{t("summaryPage.tilesInfoModal.disorder.description.sections.fifth")}</strong>
              </li>
              <li>
                <strong>
                    {t("summaryPage.tilesInfoModal.disorder.description.sections.sixths.strong")}
                </strong> &nbsp;
                  {t("summaryPage.tilesInfoModal.disorder.description.sections.sixths.normal")}
              </li>
            </ul> */}
          {/* <p className="mt-3">
              {t("summaryPage.tilesInfoModal.disorder.description.line3.normal1")} &nbsp;
              <strong> {t("summaryPage.tilesInfoModal.disorder.description.line3.strong")} </strong> &nbsp;
              {t("summaryPage.tilesInfoModal.disorder.description.line3.normal2")}
            </p> */}
        </div>
      )
    } else if (tileName === "notifs") {
      return (
        <div>
          <p className="mt-3">
            {t("summaryPage.tilesInfoModal.notifs.description.line1")}
          </p>
        </div>
      )
    } else if (tileName === 'jumpstart') {
      return (
        <p className="mt-3">{t("summaryPage.tilesInfoModal.jumpstart.description")}</p>
      )
    } else {
      return (<div>{t("summaryPage.tilesInfoModal.error.description")}</div>)
    }

  }
  return (

    <div className="modal fade" id="learnMoreModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">

      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
        <div className="modal-content">
          <div className="modal-header" style={{ borderColor: "transparent" }}>
            <img src={require('../../assets/images/FeelingBetterNowLogo.svg').default} className="fbn-logo-self-harm" />

            <button
              type="button"
              className="close x-button"
              data-dismiss="modal"
              aria-label="Close"
              data-dismiss="modal"
            >
              <span aria-hidden="true" style={{ color: "black" }}>
                &times;
              </span>
            </button>
          </div>

          <div className="modal-body therapyBody2" style={{ paddingRight: 10 }}>
            <h5 className="modal-title therapyTitle ml-0">{title}</h5>
            <Description tile={tileName.tileName.tile} />

          </div>

          <div style={{ zIndex: "0" }}>
            <img
              src={require('../../assets/images/rightEllipse.png')}
              className="right-ellipse-send-action-plan"
            />
            <img
              src={require('../../assets/images/leftEllipse.png')}
              className="left-ellipse-send-action-plan"
            />
          </div>

          <div className="modal-footer" style={{ borderTop: 'none', zIndex: 1 }}>
            <button className="submit-btn" data-dismiss="modal">{t("summaryPage.tilesInfoModal.close")}</button>
          </div>

        </div>
      </div>
    </div>


  )

}

export default withTranslation()(LearnMoreModal)