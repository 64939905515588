import React, { useContext, useEffect, useState } from "react"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import styled from "@emotion/styled"
import bg1 from "../../assets/images/summary/bg1.png"
import bg2 from "../../assets/images/summary/bg2.png"
import bg3 from "../../assets/images/summary/bg3.png"
import bg4 from "../../assets/images/summary/bg4.png"
import bg5 from "../../assets/images/summary/bg5.png"
import arrowImg from "../../assets/images/summary/arrow.png"

import { Link } from "react-router-dom"
import { baseUrl } from "../../lib/constants"
import { DownloadDetails } from "../../components/printable/PrintableDetails"
import { Button } from "react-bootstrap"
import { DownloadPAP } from "../../components/printable/PrintablePAP"
import Summary from "./Summary"
import "../../css/Summary.scss"
import { makepdf } from "../PAP/PAPMake"
import { makeassessmentpdf } from '../PAP/PAPMakeAssessment'
import { StateContext } from "../../store/state/contexts"
import useWindowDimensions from '../../utils/getDimentions';
import generatePassword from "../../utils/generatePassword";
import InformationIcon from '../../assets/images/i-large.png';
import logClickEvent from "../../utils/logClickEvent"
import axios from "axios"

const RightCornerNo = styled.div`
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 9;
`
const InfoIcon = styled.img`
  width:42px;
`


const CornerNo = styled.p`
  margin-bottom: 0;
  width: 42px;
  height: 42px;
  background: #EC5F43;;
  border-radius: 100px;
  font-size: 14px;
  line-height: 14px;
  text-align: center;
  color: #FFFFFF;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
`

const LeftCornerImage = styled.div`
  position: absolute;
  top: 10px;
  left: 12px;
  z-index: 9;
  cursor: pointer;
`

const ECaptionHoovered = styled.p`
  color: #33ca8f;
  text-transform: uppercase;
  font-size: 20px;
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0;
  // display: none;
  visibility: hidden;
  // transition: .5s;
`

const EGrid = styled.div`
  display: grid;
  grid-template-columns: 9fr 10fr;
  margin: 2.5em 1.5em;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  min-height: 17em;
  position: relative;
  @media (max-width: 1200px) {
    display: none;
  }
`

const AssessmentButton = styled.button`
  color: #33ca8f;
  text-transform: uppercase;
  border: none;
  font-weight: 600;
  display: block;
  margin-bottom: 12px;
  position: relative;
  text-align: left;
  background: none;
  &:focus {
    outline: none;
  }
  :last-child {
    margin-bottom: 0;
  }
  :hover {
    text-decoration: underline;
  }
`

const AssessmentLink = styled(Link)`
  color: #33ca8f;
  text-transform: uppercase;
  border: none;
  background-color: transparent;
  display: block;
  :hover {
    color: #33ca8f;
  }
  :after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    background: grey;
    border-radius: 50px;
    opacity: 0.55;
    transform: scale(0);
  }
  @keyframes ripple {
    0% {
      transform: scale(0);
    }
    75% {
      transform: scale(5);
    }
    100% {
      opacity: 0;
      transform: scale(0.2);
    }
  }
  :not(:active):after {
    animation: ripple 0.75s ease-out;
  }
  :after {
    visibility: hidden;
  }
  :focus:after {
    visibility: visible;
  }
`

const EGridMobile = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin: 2.5em 1.5em;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  min-height: 33em;
  position: relative;
  z-index: 10;

  @media (min-width: 1201px) {
    display: none;
  }
`

const ELeftGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
`

const ERightGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  // grid-template-rows: 1fr 1fr;
  grid-column-gap: 1em;
  grid-row-gap: 1em;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

const EBgimg = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 1;
  object-fit: cover;
  left: 0;
  top: 0;
  position: absolute;

  filter: grayscale(${(prop) => (prop.gray ? "100%" : "0%")});
  transition: all 3s;

  animation: pulse_animation ${(prop) => (prop.gray ? "1.5s" : " 0s")};

  animation-iteration-count: infinite;

  @keyframes pulse_animation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.05);
    }
    100% {
      transform: scale(1);
    }
  }
`

const commonLinkStyle = css`
  :hover {
    box-shadow: 0 2px 20px rgba(0,0,0,.1);
    // transition: .3s;
    .arrow {
      opacity: 0;
      // transition: .5s;
    }
    p:nth-of-type(1) {
      opacity: 0;
      visibility: hidden;
      // transition: .5s;
    }
    p:nth-of-type(2) {
      opacity: 1;
      display: block;
      position: relative;
      visibility: visible;
      // transition: .5s;
    }
    p.number{
      opacity: 1 !important;
      visibility: visible !important;
      // transition: .5s !important;
    }
  }

  :focus {
    outline: none;
  }

  // transition: all 0.2s;
  outline: none;
  box-shadow: 0 2px 4px rgba(0,0,0,.05);
  // transition: .5s;
  border-radius: 5px;
  display: block;
  position: relative;
  overflow: hidden;
  font-size: 18px;
  padding: 1.4em;
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  

  a {
    text-decoration: none !important;
  }

  @media (max-width: 1200px) {
    min-height: 13em;
  }

  @media (max-width: 640px) {
    p:nth-of-type(2) {
      display: none;
    }
  }
`
const ELink = styled(Link)`
  ${commonLinkStyle}
  :after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    background: grey;
    border-radius: 50px;
    opacity: 0.25;
    transform: scale(0);
  }
  @keyframes ripple {
    0% {
      transform: scale(0);
    }
    75% {
      transform: scale(5);
    }
    100% {
      opacity: 0;
      transform: scale(0.2);
    }
  }

  :not(:active):after {
    animation: ripple 0.75s ease-out;
  }
  :after {
    visibility: hidden;
  }
  :focus:after {
    visibility: visible;
  }

  @keyframes borderFade {
    0% {
      box-shadow: 0px 0 5px rgba(0,0,0,0);
    }
    50% {
      box-shadow: 0px 0 5px rgba(0,0,0,.2);
    }
    100% {
      box-shadow: 0px 0 5px rgba(0,0,0,0);
    }
  }

  animation: borderFade
    ${(prop) => (prop.gray ? "1.5s infinite" : "0s infinite")};
`

const ELinkButton = styled.button`
  ${commonLinkStyle}
  :after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 120px;
    height: 120px;
    margin-left: -60px;
    margin-top: -60px;
    background: grey;
    border-radius: 50px;
    opacity: 0.25;
    transform: scale(0);
  }
  @keyframes ripple {
    0% {
      transform: scale(0);
    }
    75% {
      transform: scale(5);
    }
    100% {
      opacity: 0;
      transform: scale(0.2);
    }
  }

  :not(:active):after {
    animation: ripple 0.75s ease-out;
  }
  :after {
    visibility: hidden;
  }
  :focus:after {
    visibility: visible;
  }

  @keyframes borderFade {
    0% {
      box-shadow: 0px 0 5px rgba(0,0,0,0);
    }
    50% {
      box-shadow: 0px 0 5px rgba(0,0,0,.2);
    }
    100% {
      box-shadow: 0px 0 5px rgba(0,0,0,0);
    }
  }

  animation: borderFade
    ${(prop) => (prop.gray ? "1.5s infinite" : "0s infinite")};
`
const ELinkDivDisorder = styled.div`
  ${commonLinkStyle}
  cursor: pointer;
  @keyframes borderFade {
    0% {
      box-shadow: 0px 0 5px rgba(0,0,0,0);
    }
    50% {
      box-shadow: 0px 0 5px rgba(0,0,0,.2);
    }
    100% {
      box-shadow: 0px 0 5px rgba(0,0,0,0);
    }
  }

  animation: borderFade
    ${(prop) => (prop.gray ? "1.5s infinite" : "0s infinite")};
`
const ELinkDiv = styled.div`
  ${commonLinkStyle}
  position:relative;
  @keyframes borderFade {
    0% {
      box-shadow: 0px 0 5px rgba(0,0,0,0);
    }
    50% {
      box-shadow: 0px 0 5px rgba(0,0,0,.2);
    }
    100% {
      box-shadow: 0px 0 5px rgba(0,0,0,0);
    }
  }

  animation: borderFade
    ${(prop) => (prop.gray ? "1.5s infinite" : "0s infinite")};
`
const ActionDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`

const LearnMoreDiv = styled.div`
  font-weight: 600;

  :hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
const ELinkDivGrey = styled.div`
  ${commonLinkStyle}
`

const ECaption = styled.p`
  color: #23255B;
  font-weight: 600;
  // transition: .5s;
  margin: 0;
`

const ETextWrapper = styled.div`
  position: relative;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #33ca8f;
  img {
    width: 9px;
    margin-left: 15px
  }
`

export const TopButtons = ({
  id,
  assessment_id,
  detailsData,
  userData,
  onToggleShareAssessmentForm,
  onToggleSendAssessmentForm,
  onToggleNotifsForm,
  progressTracker,
  downloadPAPHandler,
  sharePAPHandler,
  viewPAPHandler,
  downloadAssessmentHandler,
  shareAssessmentHandler,
  viewAssessmentHandler,
  reviewDisorderHandler,
  reviewScoresHandler,
  signUpNotifsHandler,
  reviewScoreModalMessage,
  currentTileHandler,
  assessmentDisorders,
  fullHistory,
  t,
  i18n,
  status,

  reviewDisorderScroll = () => {
    if (userData.disorders != "") {
      window.scrollTo({ top: 580, behavior: "smooth" })
      setTimeout(function () {
        document.elementFromPoint(300, 125).click()
      }, 650)
    } else {
      window.scrollTo({ top: 580, behavior: "smooth" })
    }
  },
}) => {
  const [visited, setVisited] = useState(false)
  const [assessmentDetailsVisited, setAssessmentDetailsVisited] = useState(false)
  const [notificationVisited, setNotificationVisited] = useState(false)
  const [jumpstartVisited, setJumpstartVisited] = useState(false)
  const [currentStatus, setCurrentStatus] = useState(1)
  const [clickedOnFirstOfStatus1, setClickedOnFirstOfStatus1] = useState(false)
  const [clickedOnSecondOfStatus1, setClickedOnSecondOfStatus1] = useState(false)
  const [clickedOnFirstOfStatus2, setClickedOnFirstOfStatus2] = useState(false)
  const [clickedOnFirstOfStatus3, setClickedOnFirstOfStatus3] = useState(false)

  const options = {
    withCredentials: true,
    mode: "cors",
  }

  useEffect(() => {
    if(status && status > 1){
      setCurrentStatus(status)
    }
  }, [status])

  useEffect(() => {
    if(currentStatus>1){
      setClickedOnFirstOfStatus1(true)
      setClickedOnSecondOfStatus1(true)
    }
    if(currentStatus>2){
      setClickedOnFirstOfStatus2(true)
    }
    if(currentStatus>3){
      setClickedOnFirstOfStatus3(true)
    }
  }, [currentStatus])


  const updateStatusHandler = () => {
    axios.post(baseUrl + '/assessment/status', { id: assessment_id, status: currentStatus + 1 }, options).then((response) => {
      setCurrentStatus(currentStatus + 1)
    }).catch((e) => {
      console.log(e?.message)
    })
  }

  useEffect(() => {
    if((clickedOnFirstOfStatus1 || clickedOnSecondOfStatus1) && (currentStatus == 1)){
      // call api to update status of assessment to 2
      updateStatusHandler()
    }
  }, [clickedOnFirstOfStatus1, clickedOnSecondOfStatus1])

  useEffect(() => {
    if(clickedOnFirstOfStatus2 && (currentStatus == 2)){
      // call api to update status of assessment to 3
      updateStatusHandler()
    }
  }, [clickedOnFirstOfStatus2])

  useEffect(() => {
    if(clickedOnFirstOfStatus3 && (currentStatus == 3)){
      // call api to update status of assessment to 4
      updateStatusHandler()
    }
  }, [clickedOnFirstOfStatus3])

  const { state, dispatch } = useContext(StateContext)
  const l1 = (
    <ELinkDiv
      className="top-btn"
      data-tut="top-btn-1"
      id="top-btn-1"
      gray={
        !(
          progressTracker.downloadPAPComplete |
          progressTracker.sharePAPComplete |
          progressTracker.viewPAPComplete
        )
      }
    >
      <EBgimg
        id="img"
        src={bg1}
        gray={
          !(
            progressTracker.downloadPAPComplete |
            progressTracker.sharePAPComplete |
            progressTracker.viewPAPComplete
          )
        }
      />

      <ActionDiv
        data-toggle="modal"
        data-target="#learnMoreModal"
        onClick={() => {
          currentTileHandler("pap");
          viewPAPHandler();
        }}
      >
        <h3 style={{ fontWeight: 'bold', marginTop: 10 }} >{t("summaryPage.cardSection.step")}</h3>
      </ActionDiv>
      <RightCornerNo >
        <CornerNo className="number">1</CornerNo>
      </RightCornerNo>
      <LeftCornerImage
      data-toggle="modal"
      data-target="#learnMoreModal"
      onClick={() => {
        currentTileHandler("pap");
        viewPAPHandler();
      }}
      >
        <InfoIcon src={InformationIcon} alt="info-icon"/>
      </LeftCornerImage>
      <ETextWrapper>
        <div>
          <ECaption>{t("summaryPage.cardSection.downloadTitle")}</ECaption>

          <ECaptionHoovered>

            {/* <AssessmentButton onClick={viewPAPHandler} data-tut="top-btn-1-plans-goals">
              <Link to={`/goal/${id}`}>{t("summaryPage.cardSection.myplanGoals")}</Link>
            </AssessmentButton> */}
            <AssessmentButton
              data-tut="top-btn-3-review"
              onClick={() => {
                logClickEvent('Click on Review Results', 3, '')
                // reviewDisorderHandler()
                reviewDisorderScroll()
                if(!clickedOnFirstOfStatus1){
                  setClickedOnFirstOfStatus1(true)
                }
              }}
            >
              {t("summaryPage.cardSection.review")}
            </AssessmentButton>
            {/* {console.log('reset password', generatePassword(), detailsData.user.pdfPassword)} */}
            
            <AssessmentButton data-tut="top-btn-1-review" onClick={(...params) => {
                logClickEvent('Click on Review Assessment Details', 4, '')
                viewAssessmentHandler(...params);
                if(!clickedOnSecondOfStatus1){
                  setClickedOnSecondOfStatus1(true)
                }
              }
            }>
              <Link to={`/SummaryDetails/${id}`} target="_blank" >{t("summaryPage.cardSection.papLink")}</Link>
            </AssessmentButton>
            {/* <AssessmentButton
              data-tut="top-btn-1-download-pap"
              style={{ color: visited ? '#28996c' : 'rgb(51, 202, 143)' }}
              onClick={() => {
                logClickEvent('Click on Download MAP', 3, '')
                setVisited(true);
                downloadPAPHandler();
                let passwordChanged = false;
                if (!detailsData.user || !detailsData.user.pdfPassword) {
                  let autoGeneratedPassword = generatePassword();
                  detailsData.user.pdfPassword = autoGeneratedPassword;
                  passwordChanged = true;
                }
                makepdf(
                  detailsData,
                  assessmentDisorders,
                  fullHistory,
                  "download",
                  state,
                  passwordChanged
                )
              }}
            >
              {t("summaryPage.cardSection.downloadTitle2")}
            </AssessmentButton> */}
          </ECaptionHoovered>
        </div>
        <img className="arrow" src={arrowImg} />
      </ETextWrapper>
    </ELinkDiv>
  )

  const l2 = (
    <ELinkDiv
      className="top-btn"
      style={ currentStatus > 1 ? {} : { pointerEvents: 'none', opacity: 0.8, cursor: 'not-allowed' }  }
      data-tut="top-btn-2"
      id="top-btn-2"
      gray={
        !(
          progressTracker.downloadAssessmentComplete |
          progressTracker.viewAssessmentComplete |
          progressTracker.shareAssessmentComplete
        )
      }
    >
      <EBgimg
        src={bg2}
        style={{ rotate: '180deg' }}
        gray={
          !(
            progressTracker.downloadAssessmentComplete |
            progressTracker.viewAssessmentComplete |
            progressTracker.shareAssessmentComplete
          )
        }
      />

      <ActionDiv
        data-toggle="modal"
        data-target="#learnMoreModal"
        onClick={() => {
          currentTileHandler("assessment");
          viewAssessmentHandler()
        }}
      >
        <h3 style={{ fontWeight: 'bold', marginTop: 10 }} >{t("summaryPage.cardSection.step")}</h3>
      </ActionDiv>
      <LeftCornerImage
       data-toggle="modal"
       data-target="#learnMoreModal"
       onClick={() => {
         currentTileHandler("assessment");
         viewAssessmentHandler()
       }}
      >
        <InfoIcon src={InformationIcon} alt="info-icon"/>
      </LeftCornerImage>
      <RightCornerNo>
        <CornerNo className="number">2</CornerNo>
      </RightCornerNo>
      <ETextWrapper>
        <div>
          <ECaption>{t("summaryPage.cardSection.assessment1")}</ECaption>
          <ECaptionHoovered>
            <AssessmentButton onClick={(...params) => {
              logClickEvent('Click on Review In-depth Disorder Information', 3, '')
              viewPAPHandler(...params)
              if(!clickedOnFirstOfStatus2 && currentStatus == 2){
                setClickedOnFirstOfStatus2(true)
              }
            }} data-tut="top-btn-2-review">
              <Link to={`/pap/${id}`} target="_blank" >{t("summaryPage.cardSection.reviewassessment")}</Link>
            </AssessmentButton>
            {/* <div data-tut="top-btn-2-download-assessment">
              {state.detailsDataLoading ? <>
                <AssessmentButton>
                  {t('assessmentHistory.loading')}
                </AssessmentButton>
              </>
              :
                <AssessmentButton
                  assessmentDetailsVisited={assessmentDetailsVisited}
                  userData={detailsData}
                  onClick={e => {
                    logClickEvent('Click on Download Assessment Details', 4, '')
                    setAssessmentDetailsVisited(true)
                    downloadAssessmentHandler(e)
                    let passwordChanged = false;
                    if (!detailsData.user || !detailsData.user.pdfPassword) {
                      let autoGeneratedPassword = generatePassword();
                      detailsData.user.pdfPassword = autoGeneratedPassword;
                      passwordChanged = true;
                    }
                    makeassessmentpdf(
                      detailsData,
                      assessmentDisorders,
                      fullHistory,
                      "download",
                      state,
                      passwordChanged
                    )
                  }}
                >
                  {t("summaryPage.cardSection.downloadAssessment")}
                </AssessmentButton>
              }
            </div> */}

          </ECaptionHoovered>
        </div>
        <img className="arrow" src={arrowImg} />
      </ETextWrapper>
    </ELinkDiv>
  )

  const l3 = (
    <ELinkDiv
      className="top-btn"
      data-tut="top-btn-3"
      id="top-btn-3"
      css={css`
        grid-column: 1/-1;
      `}
      gray={!progressTracker.reviewDisordersComplete}
    >
      <ActionDiv
        data-toggle="modal"
        data-target="#learnMoreModal"
        onClick={() => {
          currentTileHandler("disorder");
          reviewDisorderHandler()
        }}
      />
      <RightCornerNo>
        <CornerNo className="number">3</CornerNo>
      </RightCornerNo>
      <LeftCornerImage
       data-toggle="modal"
       data-target="#learnMoreModal"
       onClick={() => {
         currentTileHandler("disorder");
         reviewDisorderHandler()
       }}
      >
        <InfoIcon src={InformationIcon} alt="info-icon"/>
      </LeftCornerImage>
      <EBgimg src={bg3} gray={!progressTracker.reviewDisordersComplete} />

      <ETextWrapper>
        <div>
          <ECaption>{t("summaryPage.cardSection.reviewCard")}</ECaption>

          <ECaptionHoovered>
            {/* links will go here */}
            <AssessmentButton
              onClick={() => {
                logClickEvent('Click on Share MAP', 5, '')
                onToggleSendAssessmentForm();
                sharePAPHandler();
              }}
              data-tut="top-btn-1-share-pap"
            >
              {t("summaryPage.cardSection.sharePap")}
            </AssessmentButton>
            <AssessmentButton
              data-tut="top-btn-2-share-assessment"
              onClick={() => {
                logClickEvent('Click on Share Assessment Details', 5, '')
                onToggleShareAssessmentForm()
                shareAssessmentHandler();
              }}
            >
              {t("summaryPage.cardSection.share")}
            </AssessmentButton>
          </ECaptionHoovered>
        </div>

        <img className="arrow" src={arrowImg} />
      </ETextWrapper>
    </ELinkDiv>
  )

  const l4_old = (
    <ELinkDiv
      onClick={() => {
        logClickEvent('Clicks on Box', 6, '')
      }}
      className="top-btn"
      style={ currentStatus > 2 ? {} : { pointerEvents: 'none', opacity: 0.8, cursor: 'not-allowed' }  }
      data-tut="top-btn-4"
      id="top-btn-4"
      gray={!progressTracker.reviewScoresComplete}
    >
      <ActionDiv
        data-toggle="modal"
        data-target="#learnMoreModal"
        onClick={() => {
          currentTileHandler("scores");
          reviewScoresHandler();
        }}
      >
      <h3 style={{ fontWeight: 'bold', marginTop: 10 }} >{t("summaryPage.cardSection.step")}</h3>
      </ActionDiv>
      <RightCornerNo>
        <CornerNo className="number">3</CornerNo>
      </RightCornerNo>
      <LeftCornerImage
       data-toggle="modal"
       data-target="#learnMoreModal"
       onClick={() => {
         currentTileHandler("scores");
         reviewScoresHandler();
       }}
      >
        <InfoIcon src={InformationIcon} alt="info-icon"/>
      </LeftCornerImage>
      <EBgimg src={bg4} gray={!progressTracker.reviewScoresComplete} />

      <ETextWrapper>
        <div>
          <ECaption>{t("summaryPage.cardSection.diagnostic")}</ECaption>

          <ECaptionHoovered>
            <LearnMoreDiv
              data-tut="top-btn-4-review"
              onClick={() => {
                logClickEvent('Click on Share Assessment Details', 5, '')
                onToggleShareAssessmentForm()
                shareAssessmentHandler();
                if(!clickedOnFirstOfStatus3 && currentStatus == 3){
                  setClickedOnFirstOfStatus3(true)
                }
              }}
            >
              {t("summaryPage.cardSection.riskTracker")}
            </LearnMoreDiv>
          </ECaptionHoovered>
        </div>

        <img className="arrow" src={arrowImg} />
      </ETextWrapper>
    </ELinkDiv>
  )

  const l4 = (number) => (
    <ELinkDiv
      onClick={() => {
        logClickEvent('Clicks on Box', 7, '')
      }}
      className="top-btn"
      style={ currentStatus > 3 ? {} : { pointerEvents: 'none', opacity: 0.8, cursor: 'not-allowed' } }
      data-tut="top-btn-5"
      id="top-btn-5"
      gray={!progressTracker.signUpNotifsComplete}
    >
      <EBgimg src={bg5} gray={!progressTracker.signUpNotifsComplete} />

      <ActionDiv
        data-toggle="modal"
        data-target="#learnMoreModal"
        onClick={() => {
          currentTileHandler("notifs");
          // signUpNotifsHandler();
        }}

      >
        <h3 style={{ fontWeight: 'bold', marginTop: 10 }} >{t("summaryPage.cardSection.step")}</h3>
      </ActionDiv>
      <RightCornerNo>
        <CornerNo className="number">{number}</CornerNo>
      </RightCornerNo>
      <LeftCornerImage
       data-toggle="modal"
       data-target="#learnMoreModal"
       onClick={() => {
         currentTileHandler("notifs");
        //  signUpNotifsHandler();
       }}
      >
        <InfoIcon src={InformationIcon} alt="info-icon"/>
      </LeftCornerImage>
      <ETextWrapper>
        <div>
          <ECaption>{t("summaryPage.cardSection.notification")}</ECaption>

          <ECaptionHoovered>
            <Link
              data-tut="top-btn-5-schedule"
              onClick={() => {
                // signUpNotifsHandler();
                // setNotificationVisited(true);
                logClickEvent('Total # Clicks in Text Resources Section', 11, '');
              }}
              target="_blank" to={`/Education/${assessment_id}`}><LearnMoreDiv style={{ color: notificationVisited ? '#28996c' : 'rgb(51, 202, 143)' }} >{t("summaryPage.cardSection.schedule")}</LearnMoreDiv>
            </Link>
          </ECaptionHoovered>
        </div>
        <img className="arrow" src={arrowImg} />
      </ETextWrapper>
    </ELinkDiv>
  )

  const l5 = (number) => (
    <ELinkDiv
      onClick={() => {
        logClickEvent('Clicks on Box', 12, '')
      }}
      className="top-btn"
      data-tut="top-btn-5"
      id="top-btn-5"
      gray={!progressTracker.signUpNotifsComplete}
    >
      <EBgimg src={bg5} gray={!progressTracker.signUpNotifsComplete} />

      <ActionDiv
        data-toggle="modal"
        data-target="#learnMoreModal"
        onClick={() => {
          currentTileHandler("jumpstart");
        }}
      />
      <RightCornerNo>
        <CornerNo className="number">{number}</CornerNo>
      </RightCornerNo>
      <LeftCornerImage
        data-toggle="modal"
        data-target="#learnMoreModal"
        onClick={() => {
          currentTileHandler("jumpstart");
        }}
      >
        <InfoIcon src={InformationIcon} alt="info-icon"/>
      </LeftCornerImage>
      <ETextWrapper>
        <div>
          <ECaption>{t("summaryPage.cardSection.jumpstartTitle")}</ECaption>

          <ECaptionHoovered>
            <Link
              data-tut="top-btn-5-schedule"
              onClick={() => {
                setJumpstartVisited(true);
              }} to="/goal"><LearnMoreDiv style={{ color: jumpstartVisited ? '#28996c' : 'rgb(51, 202, 143)' }} >{t("summaryPage.cardSection.jumpstartLink")}</LearnMoreDiv>
            </Link>
          </ECaptionHoovered>
        </div>
        <img className="arrow" src={arrowImg} />
      </ETextWrapper>
    </ELinkDiv>
  )



  const { width } = useWindowDimensions();
  return (
    <>
      {width > 1200 &&
        <EGrid>
          <ELeftGrid>
            {l1}
            {l2}
          </ELeftGrid>

          <ERightGrid>
            {/* {l3} */}
            {l4_old}
            {l4(4)}
          </ERightGrid>
        </EGrid>
      }

      <EGridMobile>
        {l1}
        {l2}
        {/* {l3} */}
        {l4_old}
        {l4(4)}
        {/* {l4} */}
        {/* {l4(4)} */}
        {/* {l4(5)} */}
      </EGridMobile>
    </>
  )
}
