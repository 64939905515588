import React, { useState, useEffect } from "react"
import axios from "axios"
/** @jsx jsx */
import { jsx, css } from "@emotion/react"
import _get from "lodash/get"
import styled from "@emotion/styled"
import { Link } from "react-router-dom"
import { baseUrl } from "../../lib/constants"
import { checkdevice } from "../../utils/checkdevice"
// import { Navbar } from "../components/Navbar";
import "../../css/main.css"
import { disorderFiles } from "../../lib/constants"
import logo from "../../assets/images/FeelingBetterNowLogo.svg"
import layer7 from "../../assets/images/Layer7.svg"
import layer2 from "../../assets/images/Layer2.svg"
import layer10 from "../../assets/images/Layer10.svg"
import layer8 from "../../assets/images/Layer8.svg"
import layer9 from "../../assets/images/Layer9.svg"
import "../../css/pap.scss"
import { set } from "store"
import { PrintPap } from "../../api/pap"
import { Disorder } from "../../api/Disorder"

import { View } from "./PAP"

const PrintPAP = () => {
  const [assessmentDisorders, setAssessmentDisorders] = useState([])
  const [error, setError] = useState(null)
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)

    const splitSrt = window.location.href.split("/").filter((i) => !!i)
    const id = splitSrt[splitSrt.length - 1]

    PrintPap.get(id)
      .then((data) => {
        if (data && data.status && data.status === 200) {
          setAssessmentDisorders(data.data)
        } else {
          throw "Error"
        }

        if (data.length === 0) {
          setError("Assessment list is empty")
        }

        setLoading(false)
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.message
        ) {
          setError(err.response.data.message)
        } else {
          setError("Error")
        }

        setLoading(false)
      })
  }, [])

  return (
    <View
      assessmentDisorders={assessmentDisorders}
      isLoading={isLoading}
      error={error}
    />
  )
}

export default PrintPAP
