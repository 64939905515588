import { baseUrl } from "../lib/constants";
import axios from "axios";
import { logEvent } from "../index"

const options = {
  withCredentials: true,
  mode: "cors",
};

const clearCacheData = () => {
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

export const Auth = {
  init() {
    logEvent("Auth init / called");
    return axios.get(`${baseUrl}`, options);
  },
  logout() {
    logEvent("Auth logout / called");
    sessionStorage.clear();
    localStorage.clear();
    clearCacheData();
    return axios.get(`${baseUrl}/logout`, options);
  },
};

export const setRandomPassword = async (password) => {
  const body = {
    password,
  };
  try {
    const response = await axios.post(`${baseUrl}/user-profile/auto-generate-password`, body, options);
    return response;
  } catch (error) {
    console.error(error);
  }
};
