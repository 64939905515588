import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Footer from "../../components/footer/Footer"
import "../../css/AboutUs.css"
import Header from "../../components/navbar/Header"
import "../../css/PrivacyPolicyPage.css"
import "../../css/privacyPolicy.css"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../../components/error/ErrorScreen"

const Dashboard = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <div style={{ background: 'rgb(236, 248, 248)' }}>
        <Header />
        <div className="privacy-policy-text" style={{background:"#dffff3"}}>
          <div>
            <img
              src={require("../../assets/images/dashboard.png")}
              alt=""
              style={{ width: "100%" }}
            ></img>
          </div>
        </div>
        <Footer />
      </div>
    </ErrorBoundary>
  )
}

export default Dashboard
