import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import Footer from "../footer/Footer"
import "../../css/AboutUs.css"
import Header from "../navbar/Header"
import "../../css/PrivacyPolicyPage.css"
import "../../css/privacyPolicy.css"
import { ErrorBoundary } from "react-error-boundary"
import { ErrorScreen } from "../error/ErrorScreen"
import { NonceProvider } from "react-select"
import { bottom } from "@popperjs/core"

const UserResults = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <ErrorBoundary FallbackComponent={ErrorScreen}>
      <div style={{ background: 'rgb(236, 248, 248)' }}>
        <Header />
        <div className="privacy-policy-text" style={{background:"#dffff3"}}>
          <h2 className="title">User Results</h2>
          <hr></hr>

          <p className="paragraph">
            <b style={{ paddingBottom: "5px" }}>
              What our users are telling us!
            </b>
            <br></br>
            In the field of primary care only one in eight patients receive both
            a correct diagnosis and correct treatment.
            <br></br>
            <br></br>
            <b>FeelingBetterNow® Users Engage with their Health</b>
            <br></br>
            32% of returning users say they visited a mental health professional
            since their last assessment.
            <br></br>
            <br></br>
            <li style={{ listStyle: "none" }}>
              <ul>
                FeelingBetterNow® encourages users to take their Medical
                Action Plan and schedule and encounter with their Care Provider
                or mental health professional.
              </ul>
            </li>
            <b>Quality of Care Improves for FeelingBetterNow® Users</b>
            <br></br>
            57% of those who visited their Care Provider were given a new or
            updated diagnosis and treatment plan.
            <br></br>
            <br></br>
            FeelingBetterNow® has been designed to improve diagnosis and
            treatment based on Best Practice principles.
            <br></br>
            <br></br>
            85% of the new or updated treatment plans included psychotherapy, or
            medication, or both.
            <br></br>
            <br></br>
            FeelingBetterNow® provides a mechanism for Providers to evaluate
            their treatment based on established and current Clinical Practice
            Guidelines.
            <br></br>
            <br></br>
            36% of those who visited a mental health professional were referred
            to another provider for further care.
            <br></br>
            <br></br>
            FeelingBetterNow® reinforces Best Practice Care i.e., a referral
            from a primary care Provider to a Psychiatrist or to a
            Psychotherapist.
          </p>
        </div>
        <Footer />
      </div>
    </ErrorBoundary>
  )
}

export default UserResults
