import React, { useContext, useEffect } from 'react';
import { Navigate, useParams, useNavigate } from 'react-router-dom';
import { loginUrl } from '../../lib/constants';
import { StateContext } from '../../store/state/contexts';

export function PrivateRoute({ component: Component, ...rest }) {
  const { state } = useContext(StateContext);
  let params = useParams()
  let navigate = useNavigate()

  useEffect(() => {
    if (state.auth.authInitialized && !state.auth.isLoggedIn) {
      window.open(loginUrl(localStorage.getItem('tempEnv') ? 'temp' : undefined), '_self');
    }
  }, [state.auth.authInitialized, !state.auth.isLoggedIn]);

  if (!state.auth.authInitialized) {
    return null;
  }

  return state.auth.authInitialized && state.auth.isLoggedIn ? (
    <Component {...rest} match={{ params }} navigate={navigate} />
  ) : (
    <Navigate
      to={{
        pathname: '/',
      }}
    />
  )
}