import { baseUrl } from "../lib/constants"
import { logEvent } from "../index"
const store = require('store')
export const sendPDFEmail = async (email, base64data, assessment_id, isPAP = false) => {
  const lang = store.get('lang')
  var requestOptions = {
    method: "POST",
    credentials: "include",
    mode: "cors",
    body: JSON.stringify({ attachment: base64data, email: email, isPAP }),
    headers: {
      "Content-Type": "application/json",
      lang
    },
  }
  var response = await fetch(
    `${baseUrl}/email/file/${assessment_id}`,
    requestOptions
  )
    .then((res) => {
      logEvent("sendPDFEmail /email/file successful");
      return res
    })
    .catch((e) => {
      logEvent("sendPDFEmail /email/file failed");
      console.log(e);
    })
  if (response.ok) return response
  else return 0
}

export const sendAssessmentPDFEmail = async (
  email,
  base64data,
  assessment_id
) => {
  const lang = store.get('lang')
  var requestOptions = {
    method: "POST",
    credentials: "include",
    mode: "cors",
    body: JSON.stringify({
      attachment: base64data,
      email: email,
      isPAP: false,
    }),
    headers: {
      "Content-Type": "application/json",
      lang
    },
  }
  var response = await fetch(
    `${baseUrl}/email/file/${assessment_id}`,
    requestOptions
  )
    .then((res) => {
      logEvent("sendAssessmentPDFEmail /email/file failed");
      return res
    })
    .catch((e) => {
      logEvent("sendAssessmentPDFEmail /email/file failed");
      console.log(e)
    })
  if (response.ok) return response
  else return 0
}
